import React from 'react'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import GridViewIcon from '@mui/icons-material/GridView'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import GroIconDark from '../../../images/gro logo dark font.png'
import GroMarketingIcon from '../../Icons/GroMarketingIcon'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import ReceiptIcon from '@mui/icons-material/Receipt'

const MainNavigationSection = ({ handleNavigation, open, selectedPath }) => {
  const theme = useTheme()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
  return (
    <List>
      {[
        { label: 'Dashboard', path: 'dashboard' },
        { label: 'New Client Onboards', path: 'nco' },
        { label: 'Accounting', path: 'accounting' },
        // { label: 'Team Tasks', path: 'teamtasks' },
        // { label: 'New Client Onboards', path: 'newclientonboards' },
        // { label: 'Creative Requests', path: 'creativerequests' },
        // Add more items here if necessary
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              backgroundColor: item.path === selectedPath.replace(/^\//, '') ? bgSelectColor : 'transparent', // Adjust color as needed
              '&:hover': {
                backgroundColor: bgHoverColor, // Adjust hover color as needed
              },
            }}
            onClick={() => handleNavigation(item.path)}
          >
            <Tooltip enterDelay={500} leaveDelay={0} title={item.label} placement='right'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {/* You can decide what icon to use based on index or some other logic */}
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                {index === 0 && (
                  <GridViewIcon
                    sx={{
                      color: 'rgb(5, 190, 113)',
                    }}
                  />
                )}
                {index === 1 && (
                  <AddRoadIcon
                    sx={{
                      color: 'rgb(159, 90, 253, 1)',
                    }}
                  />
                )}
                {index === 2 && (
                  <ReceiptIcon
                    sx={{
                      color: 'rgba(107, 185, 240, 1)',
                    }}
                  />
                )}
                {/* {index === 1 ? (
                  <TaskAltIcon
                    sx={{
                      color: 'rgb(255, 68, 161)',
                    }}
                  />
                ) : null}

                {index === 2 ? (
                  <DashboardCustomizeIcon
                    sx={{
                      color: 'rgb(35, 146, 173)',
                    }}
                  />
                ) : null}
                {index === 3 ? (
                  <EmojiObjectsIcon
                    sx={{
                      color: 'rgb(132, 101, 192)',
                    }}
                  />
                ) : null} */}
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              sx={{
                opacity: open ? 1 : 0,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                }}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default MainNavigationSection
